<template>
  <div class=""></div>
</template>

<script>
export default {
  name: 'YandexMap',
  props: ['id'],
  mounted () {
    let yandexMap = document.createElement('script')
    yandexMap.setAttribute('src', 'https://api-maps.yandex.ru/services/constructor/1.0/js/?um=' + this.id + '&amp;width=100%&amp;height=300&amp;lang=ru_RU&amp;id=yandex-map')
    document.head.appendChild(yandexMap)
  }
}
</script>

<style scoped>

</style>
