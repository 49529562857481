<template>
    <div class="col-md-4 col-sm-4 col-xs-12" v-if="">
      <div class="kris-ulh2-tit h2">&nbsp;</div>
      <ul class="kris-ul-h2">

        <li v-if="data.predsedatel_mrsd">
          <div class="h2">Председатель МРСД</div>
          <p>{{data.predsedatel_mrsd.name_fio}}</p>
          <p>
            <a :href="'tel:' + data.predsedatel_mrsd.telephon">{{ data.predsedatel_mrsd.telephon }}</a>
          </p>
          <p>
            <a :href="'mailto:' + data.predsedatel_mrsd.email">{{ data.predsedatel_mrsd.email }}</a>
          </p>
        </li>

        <li v-if="data.inspector">
          <div class="h2">Инспектор округа</div>
          <p>{{ data.inspector.name }}</p>
          <p>
            <a :href="'tel:+7' + data.inspector.telephon">+7{{ data.inspector.telephon }}</a>
          </p>
          <p>
            <span>Добавочный: </span>
            {{ data.inspector.addtelephon }}
          </p>
          <p>
            <a :href="'mailto:' + data.inspector.email">{{ data.inspector.email }}</a>
          </p>
        </li>

        <li v-if="data.kurator">
          <div class="h2">Куратор округа</div>
          <p v-if="data.kurator.name">
            {{ data.kurator.name }}
          </p>
          <p v-if="data.kurator.telephon">
            <a :href="'tel:+7' + data.kurator.telephon">+7 {{ data.kurator.telephon }}</a>
          </p>
          <p v-if="data.kurator.addtelephon">
            <span>Добавочный: </span>
            {{ data.kurator.addtelephon }}
          </p>
          <p>
            <a :href="'mailto:' + data.kurator.email">
              {{ data.kurator.email }}
            </a>
          </p>
        </li>
        <li v-bind:key="key" v-for="(item,key) in data.dop_kurators">
          <p>{{ item.name }}</p>
          {% if is defined %}
          <p v-if="item.telephon ">
            <a :href="'tel:+7' + item.telephon">
              +7 {{ item.telephon }}
            </a>
          </p>
          <p v-if="item.addtelephon">
            <span>Добавочный: </span>
            {{ item.addtelephon }}
          </p>
          <p v-if="item.email">
            <a :href="'mailto:' + item.email ">
              {{ item.email }}
            </a>
          </p>
        </li>

        <li v-if="data.predsedatel_us">
          <div class="h2">Представитель учредителя в Управляющем совете</div>
          <p v-if="data.predsedatel_us.name">
            {{ data.predsedatel_us.name }}
          </p>
          <p v-if="data.predsedatel_us.telephon">
            <a :href="'tel:+7'+ data.predsedatel_us.telephon ">
              +7 {{ data.predsedatel_us.telephon }}
            </a>
          </p>
          <p v-if="data.predsedatel_us.addtelephon">
            <span>Добавочный: </span>
            {{ data.predsedatel_us.addtelephon }}
          </p>
          <p v-if="data.predsedatel_us.email">
            <a :href="'mailto:'+ data.predsedatel_us.email">
              {{ data.predsedatel_us.email }}
            </a>
          </p>
        </li>

        <li v-if="data.helpline_mrsd">
          <div class="h2" v-if="data.helpline_mrsd.caption">
            {{ data.helpline_mrsd.caption }}
          </div>
          <p v-if="data.helpline_mrsd.telephon">
            <a :href="'tel:+7' + data.helpline_mrsd.telephon">
              +7 {{ data.helpline_mrsd.telephon }}
            </a>
          </p>
          <p v-if="data.helpline_mrsd.email">
            <a :href="'mailto:' + data.helpline_mrsd.email">
              {{ data.helpline_mrsd.email }}
            </a>
          </p>
        </li>
      </ul>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Curators',
  computed: {
    ...mapState({
      data: 'dataCurators'
    })
  }
}
</script>

<style scoped>

</style>
