<template>
  <div v-scroll="handleScroll">
    <div class="row f-top" >
      <div class="col-md-8 col-sm-8 col-xs-12">
        <div class="row">
          <addresses />
          <contacts />
        </div>
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12" v-if="params.yandexMapId">
            <div class="" id="yandex-map"></div>
            <yandex-map :id="params.yandexMapId"/>
          </div>
        </div>
      </div>
      <curators />
    </div>
    <div class="row f-top" >

    </div>
    <div class="row f-bot">
      <div class="col-md-12">
        <div class="kris-ufoot-info social">
          <a v-if="rss" :href="rss" target="_blank">RSS</a>
          <!--<span v-if="typeof (params.founder) !== 'undefined' && params.founder == 'Департамент образования города Москвы'">-->
            <!--| <a href="https://ask.educom.ru" target="_blank">Обращение в ДОгМ</a>-->
          <!--</span>-->
          <span v-if="metrika">
            | <a :href="metrika" target="_blank">Статистика посещаемости сайта</a>
          </span>
<!--          <span class="social_networks">-->
<!--            <a v-if="params.social_facebook" :href="params.social_facebook" class="social_facebook" target="_blank"></a>-->
<!--            <a v-if="params.social_vk" :href="params.social_vk" class="social_vk" target="_blank"></a>-->
<!--            <a v-if="params.social_youtube" :href="params.social_youtube" class="social_youtube" target="_blank"></a>-->
<!--            <a v-if="params.social_instagram" :href="params.social_instagram" class="social_instagram" target="_blank"></a>-->
<!--            <a v-if="params.social_google_plus" :href="params.social_google_plus" class="social_google_plus" target="_blank"></a>-->
<!--            <a v-if="params.social_twitter" :href="params.social_twitter" class="social_twitter" target="_blank"></a>-->
<!--            <a v-if="params.social_ok" :href="params.social_ok" class="social_ok" target="_blank"></a>-->
<!--            <a v-if="params.social_mail_ru" :href="params.social_mail_ru" class="social_mail_ru" target="_blank"></a>-->
<!--          </span>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Addresses from './components/Address'
import Contacts from './components/Contacts'
import Curators from './components/Curators'
import YandexMap from './components/YandexMap'

export default {
  name: 'app',
  components: {
    'addresses': Addresses,
    'contacts': Contacts,
    'curators': Curators,
    'yandex-map': YandexMap
  },
  created () {
    this.$store.commit('setEoId', document.body.getAttribute('data-eo-id'))
    this.$store.commit('setMetrika', document.getElementById('app-footer').getAttribute('data-metrika'))
    this.$store.commit('setRss', document.getElementById('app-footer').getAttribute('data-rss'))
  },
  computed: {
    ...mapState({
      metrika: 'metrika',
      rss: 'rss',
      info: 'dataInfo',
      curators: 'dataCurators',
      params: 'dataParams'
    })
  },
  methods: {
    handleScroll (evt, el) {
      // console.log(window.pageYOffset + ' - ' + el.getBoundingClientRect().top)
      if (
        // window.scrollY >= el.getBoundingClientRect().top
        window.pageYOffset >= el.getBoundingClientRect().top
      ) {
        if (this.info === false) {
          this.$store.commit('setDataInfo', [])
          this.$store.dispatch('getDataInfo')
          this.$store.dispatch('getDataParams')
        }
        if (this.curators === false) {
          this.$store.commit('setDataCurators', [])
          this.$store.dispatch('getDataCurators')
        }
      }
    }
  }
}
</script>

<style lang="less">
  #yandex-map{
    padding-left:40px;
  }
</style>
