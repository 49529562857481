<template>
    <div class="col-md-6 col-sm-6 col-xs-12" v-if="params">
      <div class="kris-ulh2-tit h2">Наш адрес</div>
      <ul class="kris-ul-h2">
        <li>
          <div class="h2">Адрес:</div>
          <p v-if="params.address">
            <span v-if="params.ind">{{ params.ind }},</span>
            {{ params.address }}
          </p>
        </li>
        <li v-if="stations">
          <div class="h2">Станция метро:</div>
          <p v-bind:key="station.id" v-for="station in stations">
            {{ station.name }}
          </p>
        </li>
        <li v-if="params.route">
          <div class="h2">Как нас найти:</div>
          <div v-html="params.route"></div>
        </li>
      </ul>
    </div>
    <div v-else class="col-md-4 col-sm-4 col-xs-12" >Загрузка данных</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Address',
  computed: {
    ...mapState({
      info: 'dataInfo',
      params: 'dataParams',
      stations: 'dataStationsEo'
    })
  },
  created () {
    this.$store.dispatch('getStationsEo')
  }
}
</script>

<style scoped>

</style>
