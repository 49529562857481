<template>
  <div class="col-md-6 col-sm-6 col-xs-12" >
    <div class="kris-ulh2-tit h2">Контактные данные</div>
    <ul class="kris-ul-h2">
      <li v-if="info">
        <div v-if="typeof(info.chief_position) !== 'undefined'" class="h2">{{ info.chief_position }}</div>
        <div v-else class="h2">Директор:</div>
        <p v-if="typeof (info.xa_director) !== 'undefined'">{{ info.xa_director }}</p>
        <p v-if="typeof (info.director_email) !== 'undefined'">
          <a :href="'mailto:'+ info.director_email">{{ info.director_email }}</a>
        </p>
        <p v-if="typeof (info.tel_city) !== 'undefined'">
          <a v-if="info.tel_city" :href="'tel:+7 ' + info.tel_city ">+7 {{ info.tel_city }}</a>
        </p>
        <p v-if="typeof (info.user_telephon_city) !== 'undefined'">
          <a v-if="info.user_telephon_city" :href="'tel:+7 ' + info.user_telephon_city ">+7 {{ info.user_telephon_city }}</a>
        </p>
<!--        <p v-if="typeof (info.tel_mobil_public) !== 'undefined'">-->
<!--          <a v-if="info.tel_mobil_public" :href="'tel:+7 ' + info.tel_mobil_public">+7 {{ info.tel_mobil_public }}</a>-->
<!--        </p>-->
<!--        <p v-if="typeof (params.director_phone_extra) !== 'undefined'">-->
<!--          <span>Добавочный:</span> {{ params.director_phone_extra }}-->
<!--        </p>-->
      </li>
      <li v-else>Загрузка данных...</li>
      <li v-if="params">
        <div v-if="typeof (params.secretary_post) !== 'undefined'" class="h2">
          <p>{{ params.secretary_post }}</p>
        </div>
        <div v-else class="h2">
          <p>Секретарь:</p>
        </div>
        <p v-if="typeof (params.secretary_name) !== 'undefined' ">{{ params.secretary_name }}</p>
        <p v-if="typeof (params.secretary_phone) !== 'undefined'">
          <a :href="'tel:' + params.secretary_phone ">{{ params.secretary_phone }}</a>
        </p>
        <p v-if="typeof (params.secretary_phone_extra) !== 'undefined'">
          <span>Добавочный:</span> {{ params.secretary_phone_extra }}
        </p>
      </li>
      <li v-if="info">
        <div class="h2">Адрес электронной почты:</div>
        <p>
          <a :href="'mailto:' + info.email ">{{ info.email }}</a>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Contacts',
  computed: {
    ...mapState({
      info: 'dataInfo',
      params: 'dataParams'
    })
  }
}
</script>

<style scoped>

</style>
