import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    eoId: false,
    metrika: false,
    rss: false,
    ekis: 'https://st.educom.ru',
    info: '/soap/process.php?do=getFileDirector&eo_id=',
    api: 'https://info-sites.mskobr.ru',
    params: '/api/params',
    curators: '/api/ekis/curators/info.json',
    metro: '/api/metro-stations.json',
    dataInfo: false,
    dataParams: false,
    dataCurators: false,
    dataStationsEo: false
  },
  mutations: {
    setEoId (state, value) {
      state.eoId = value
    },
    setMetrika (state, value) {
      state.metrika = value
    },
    setRss (state, value) {
      state.rss = value
    },
    setDataInfo (state, value) {
      state.dataInfo = value
    },
    setDataParams (state, value) {
      state.dataParams = value
    },
    setDataCurators (state, value) {
      state.dataCurators = value
    },
    setDataStationsEo (state, value) {
      state.dataStationsEo = value
    }
  },
  actions: {
    getDataInfo () {
      return new Promise((resolve, reject) => {
        let path = this.state.ekis + this.state.info + this.state.eoId
        Axios({
          url: path,
          method: 'get'
        }).then((res) => {
          if (res.status === 200) {
            this.commit('setDataInfo', res.data)
            resolve()
          } else {
            this.commit('setDataInfo', [])
            reject(res)
          }
        }).catch((err) => {
          reject(err)
        })
      })
    },
    getDataParams () {
      let path = this.state.api + this.state.params + '.json?eoId=' + this.state.eoId
      Axios({
        url: path,
        method: 'GET'
      }).then((res) => {
        if (res.status === 200 && res.data.response === 'success') {
          this.commit('setDataParams', res.data.result)
        }
      })
    },
    getDataCurators () {
      const $this = this
      return new Promise((resolve, reject) => {
        const path = this.state.api + this.state.curators + '?eoId=' + this.state.eoId
        if ($this.state.dataCurators !== false) {
          Axios({
            url: path,
            method: 'get'
          }).then((res) => {
            if (res.data.response === 'success') {
              $this.commit('setDataCurators', res.data.data)
              resolve(res.data.data)
            } else {
              $this.commit('setDataCurators', [])
              reject(res.data.data)
            }
          }).catch((err) => {
            reject(err)
          })
        }
        resolve($this.state.dataCurators)
      })
    },
    getStationsEo ({ state, commit }) {
      return new Promise((resolve, reject) => {
        const path = state.api + state.metro + '?eoId=' + state.eoId
        Axios({
          'url': path,
          'method': 'get'
        }).then((res) => {
          if (res.data.response === 'success') {
            commit('setDataStationsEo', res.data.result)
            resolve(res)
          }
        }).catch((err) => {
          commit('setDataStationsEo', [])
          reject(err)
        })
      })
    },
  }
})
